import store from '@/store/index';

/* eslint-disable import/prefer-default-export */
import { setAxiosHeader } from '@/services/apiAxios';
import { seAdminAxiosHeader } from '@/services/apiAdminAxios';
import { getToken, destroyToken, getAdminToken } from '@/services/jwt.service';
// import { ADMIN_AUTH, AUTH } from '@/store/types';
// import apiAxios from '@/services/apiAxios';
import { AUTH } from '@/store/types';

export const pagesGurd = async (next) => {
  // Check if the current URL contains 'account'

  // Check if the URL contains 'account' and 'tab=7'

  const token = getToken();

  // next();
  if (!token) {
    next('/login');
  } else {
    setAxiosHeader(token);
    const res = await store.dispatch(AUTH.ACTION_SET_USER_DATA);
    if (res && res.email) {
      next();
    } else {
      destroyToken();
      next('/login');
    }
  }
};

export const authGurd = async (next) => {
  const token = getToken();
  if (token) {
    next('/dashboard');
  } else {
    next();
  }
};

export const AdminPagesGurd = async (next) => {
  const token = getAdminToken();

  console.log('AdminPagesGurd', token);

  // next();
  if (!token) {
    next('/admin/login');
  } else {
    seAdminAxiosHeader(token);
    // TODO: check if user exist
    next();

    // const res = await store.dispatch(ADMIN_AUTH.ACTION_ADMIN_SET_USER_DATA);
    // if (res && res.email) {
    //   next();
    // } else {
    //   destroyAdminToken();
    //   next('/admin/login');
    // }
  }
};

export const adminAuthGurd = async (next) => {
  const token = getAdminToken();
  if (token) {
    next('/admin');
  } else {
    next();
  }
};
