<template>
  <section class="members-data">
    <div class="w-full mt-4 flex flex-wrap items-center justify-between">
      <div>
        <Button type="primary" title="Decline" ghost class="rounded shadow flex items-center gap-2">
          Generate spreadsheet
          <PhMicrosoftExcelLogo weight="duotone" :size="16" />
        </Button>
      </div>
      <div class>
        <InputSearch
          class="rounded-md min-w-[320px]"
          placeholder="Search for email / business name"
          v-model:value="search"
          clear
          @change="handleSearch"
        />
      </div>
    </div>

    <QueryAlert :query="employersQuery" />

    <Modal
      :visible="creditModal"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="creditModal = false"
      @cancel="creditModal = false"
    >
      <div class="p-4">
        <h2>You can edit discount and total price</h2>
        <Row>
          <Col :span="24">
            <CustumNumberInput
              name="discount"
              label="Discount"
              :rules="{
                required: true,
                min: 0,
              }"
              @setInput="setInput2"
              :defaultData="discount || 0"
            />
          </Col>
        </Row>
        <Row>
          <Col :span="24">
            <CustumNumberInput
              name="total_price"
              label="Total price"
              :rules="{
                required: true,
                min: 0,
              }"
              :defaultData="total_price || 0"
              @setInput="setInput2"
            />
          </Col>
        </Row>
        <Button type="primary" block class="mt-4" @click="handleEditSubscription">Edit</Button>
      </div>
    </Modal>

    <div class="mt-2 md:mt-3 lg:mt-4">
      <Table
        :data-source="tableFormatedData"
        :columns="columns"
        :loading="employersQuery && (employersQuery.isFetching || employersQuery.isLoading)"
        :pagination="false"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <TypographyParagraph
              ellipsis
              :content="record.community_name + ', ' + record.name"
              class="text-text whitespace-nowrap !mb-0"
            />
          </template>
          <template v-if="column.key === 'linked_members'">
            <TypographyParagraph
              ellipsis
              :content="record.real_count"
              class="text-text whitespace-nowrap !mb-0"
            />
          </template>
          <template v-if="column.key === 'total_credit'">
            <TypographyParagraph
              ellipsis
              :content="record.total_benefits"
              class="text-text whitespace-nowrap !mb-0"
            />
          </template>
          <template v-if="column.key === 'redeemed_credit'">
            <TypographyParagraph
              ellipsis
              :content="-record.redeemed_credit"
              class="text-text whitespace-nowrap !mb-0"
            />
          </template>
          <template v-if="column.key === 'remaining_credit'">
            <TypographyParagraph
              ellipsis
              :content="(record.total_benefits + record.redeemed_credit).toFixed(2)"
              class="text-text whitespace-nowrap !mb-0"
            />
          </template>
          <template v-if="column.key === 'joined'">
            <span v-if="record.status" class="whitespace-nowrap">
              {{ getDate(record.joined) }}, approved
              {{ record.approvedOn ? 'on ' + getDate(record.approvedOn) : '' }}
            </span>
            <span v-else class="w-full flex gap-1 text-yellow-600">
              {{ getDate(record.joined) }}
              <PhHourglassSimpleMedium weight="duotone" size="18" title="pending" />
            </span>
          </template>

          <template v-if="column.key === 'plan'">
            <span v-if="record.plan" class="whitespace-nowrap text-center capitalize block">{{
              record.plan.replace('_', ' ')
            }}</span>
            <span v-else class="w-full flex justify-center text-yellow-600">N/A</span>
          </template>

          <template v-if="column.key === 'action'">
            <div class="flex items-center gap-2">
              <!-- Decline  -->
              <Popconfirm
                title="Are you sure you want to edit subscription ?"
                ok-text="Yes"
                cancel-text="No"
                :loading="statusLoading"
                @confirm="() => handleEdit(record)"
              >
                <Button
                  type="default"
                  size="small"
                  title="Edit subscription"
                  class="btn-warning rounded shadow flex items-center gap-2"
                >
                  <PhPen weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
              <!-- Decline  -->
              <Popconfirm
                title="Are you sure you want to decline?"
                ok-text="Yes"
                cancel-text="No"
                :loading="statusLoading"
                @confirm="() => handleUpdateStatus(record.key, 0)"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Decline"
                  class="btn-warning rounded shadow flex items-center gap-2"
                >
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Approve  -->
              <Popconfirm
                title="Are you sure you want to approve?"
                ok-text="Yes"
                cancel-text="No"
                :loading="statusLoading"
                @confirm="() => handleUpdateStatus(record.key, 1)"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Approve"
                  class="rounded shadow flex items-center gap-2"
                >
                  <PhCheck weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <Popconfirm
                title="Do you want to login with this account?"
                ok-text="Yes"
                cancel-text="No"
                :loading="statusLoading"
                @confirm="() => signAsEmployer(record?.email, 1)"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Sign as"
                  class="rounded shadow flex items-center gap-2"
                >
                  <PhDoor weight="duotone" :size="16" />
                </Button>
              </Popconfirm>

              <!-- Disable -->
              <Popconfirm
                title="Are you sure you want to disable?"
                ok-text="Yes"
                cancel-text="No"
                :loading="statusLoading"
                @confirm="() => handleUpdateStatus(record.key, -1)"
              >
                <Button
                  type="primary"
                  size="small"
                  title="Disable"
                  class="btn-error rounded shadow flex items-center gap-2"
                >
                  <PhProhibit weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </template>
        </template>
      </Table>

      <div v-if="employersData && employersData.meta" class="mt-6 flex items-center justify-center">
        <Pagination
          :current="employersData?.meta?.current_page"
          :total="employersData?.meta?.total"
          show-less-items
          :page-size="employersData?.meta?.per_page"
          @change="handlePaginationChange"
        />
      </div>
    </div>

    <!-- query apis  -->
    <EmployersQuery
      :page="page"
      :per_page="15"
      :keyword="keyword"
      :key="`${page}-${15}-${keyword}`"
    />
  </section>
</template>
<script>
/* eslint-disable */
import {
  Table,
  TypographyParagraph,
  Popconfirm,
  Button,
  InputSearch,
  Pagination,
  message,
  Modal,
  Row,
  Col,
} from 'ant-design-vue';
import {
  PhHourglassSimpleMedium,
  PhCheck,
  PhX,
  PhProhibit,
  PhMicrosoftExcelLogo,
  PhDoor,
  PhPen,
} from 'phosphor-vue';
import dayjs from 'dayjs';

import EmployersQuery from '@/services/queries/admin/employers/EmployersQuery.vue';

import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import debounce from 'lodash.debounce';
import handleResErrors from '@/utils/handleResErrors';
import { saveToken } from '@/services/jwt.service';
import employersApi from '../../../services/apis/admin/employers.api';

export default {
  components: {
    Table,
    Button,
    TypographyParagraph,
    Popconfirm,
    PhHourglassSimpleMedium,
    InputSearch,
    EmployersQuery,
    Pagination,
    PhCheck,
    PhDoor,
    Modal,
    CustumNumberInput,
    PhPen,
    Col,
    Row,
    PhX,
    PhProhibit,
    PhMicrosoftExcelLogo,
  },
  data() {
    return {
      columns: [
        // {
        //   title: '#',
        //   key: 'key',
        //   dataIndex: 'key',
        // },

        {
          title: 'Business Name',
          key: 'name',
          dataIndex: 'name',
          // sorter: (a, b) => {
          //   if (a.name > b.name) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Email Address',
          dataIndex: 'email',
          key: 'email',
          // sorter: (a, b) => {
          //   if (a.email > b.email) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Subscription Plan',
          dataIndex: 'plan',
          key: 'plan',
          // sorter: (a, b) => {
          //   if (a.plan > b.plan) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Number of members',
          dataIndex: 'members',
          key: 'members',
          // sorter: (a, b) => {
          //   if (a.members > b.members) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Linked members',
          dataIndex: 'linked_members',
          key: 'linked_members',
          // sorter: (a, b) => {
          //   if (a.members > b.members) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Total credit',
          dataIndex: 'total_credit',
          key: 'total_credit',
          // sorter: (a, b) => {
          //   if (a.members > b.members) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Redeemed credit',
          dataIndex: 'redeemed_credit',
          key: 'redeemed_credit',
          // sorter: (a, b) => {
          //   if (a.members > b.members) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },
        {
          title: 'Remaining credit',
          dataIndex: 'remaining_credit',
          key: 'remaining_credit',
          // sorter: (a, b) => {
          //   if (a.members > b.members) {
          //     return 1;
          //   }
          //   return -1;
          // },
        },

        {
          title: 'Joined on & Status',
          dataIndex: 'joined',
          key: 'joined',
          // sorter: (a, b) => {
          //   const aDate = new Date(a.joined).getTime();
          //   const bDate = new Date(b.joined).getTime();
          //   return bDate - aDate;
          // },
        },

        {
          title: '',
          key: 'action',
          fixed: 'right',
        },
      ],

      tableData: [
        {
          key: '1',
          name: 'Daniel Martin',
          email: 'danielmartin@gmail.com',
          joined: 1664882267,
          plan: 'free',
          members: 30,
        },

        {
          key: '2',
          name: 'Sara Doe',
          email: 'Sara@gmail.com',
          joined: 1601810267,
          plan: 'premium',
          members: 30,
        },
        // 1664882267 1601810267
        ...Array(50)
          .fill('dd')
          .map((_, ix) => ({
            key: ix + 3,
            name: `John ${ix + 3}`,
            email: `johndoe${ix + 3}@gmail.com`,
            joined: this.getRandomInt(1420063200000, 1681478087456),
            plan: 'premium',
            members: this.getRandomInt(10, 500),
          })),
      ],
      search: '',
      searchData: [],
      page: 1,
      pageSize: 15,
      discount: 0,
      total_price: 0,
      userId: null,
      name: '',
      email: '',
      modalMemb: null,
      delLoading: false,
      keyword: '',
      creditModal: false,
      statusLoading: false,
    };
  },
  computed: {
    employersQuery() {
      return this.$store.state.adminEmployers.employersQuery;
    },
    employersData() {
      return this.$store.getters.employersData;
    },
    tableFormatedData() {
      if (!this.employersData || !this.employersData.data) return [];

      const data = this.employersData.data.map((d, ix) => ({
        // eslint-disable-next-line no-underscore-dangle
        key: d._id || d.id || ix,
        userId: d?._id,
        name: d.name,
        email: d.email,
        joined: d.created_at,
        status: d.status,
        approvedOn: d.approved_on || null,
        plan: d.subscription_plan,
        members: d.community_size,
        community_name: d.community_name,
        activeSubscription: d?.active_subscription ? 1 : 0,
        discount: d?.active_subscription ? d?.active_subscription?.discount : 0,
        total_price: d?.active_subscription ? d?.active_subscription?.total_price : 0,
        real_count: d?.real_count,
        redeemed_credit: parseFloat(d?.redeemed.toFixed(2)),  
        total_benefits: parseFloat(d?.total_benefits.toFixed(2)),
      }));
      return data;
    },
  },
  methods: {
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    getRandomInt(min, max) {
      const xmin = Math.ceil(min);
      const xmax = Math.floor(max);
      return Math.floor(Math.random() * (xmax - xmin + 1)) + min;
    },
    getDate(d) {
      // February 18, 2022, 4:43 am
      return dayjs(d).format('MMM DD, YYYY');
    },
    handleSearch(e) {
      this.debounceSearch(e.target.value, this);
    },
    debounceSearch: debounce((search, app) => {
      // eslint-disable-next-line no-param-reassign
      app.keyword = search;
      // eslint-disable-next-line no-param-reassign
      app.page = 1;
      // eslint-disable-next-line no-param-reassign
      app.pageSize = 15;
    }, 400),
    async handleUpdateStatus(id, status) {
      this.statusLoading = true;
      try {
        const res = await employersApi.updateEmployer({ id, status });
        if (res) {
          message.success('Status updated successfully');
        }
        if (this.employersQuery) {
          this.employersQuery.refetch();
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      } finally {
        this.statusLoading = false;
      }
    },
    setInput2(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
      if (val.discount) {
        this.discount = val.discount.value;
      }
      if (val.total_price) {
        this.total_price = val.total_price.value;
      }
    },
    handleEdit(record) {
      if (record?.activeSubscription) {
        this.discount = record?.discount;
        this.total_price = record?.total_price;
        this.userId = record?.userId;
        this.creditModal = 1;
      } else {
        message.error('No active subscription');
      }
    },
    async handleEditSubscription() {
      try {
        let id = this.userId;
        let discount = this.discount;
        let total_price = this.total_price;

        const res = await employersApi.editEmployerSubscription({ id, discount, total_price });
        if (res) {
          message.success('updated successfully');
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
    },
    async signAsEmployer(email) {
      try {
        const res = await employersApi.signAsEmployer({ email });
        if (res.status) {
          if (res.is_active) {
            message.success('logged in successfully');
            localStorage.setItem('is_signas', true);
            saveToken(res.accessToken);
            this.$router.push('/dashboard');
          }
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
    },
  },
};
</script>
