<template>
  <Modal :visible="creditModal" :footer="null" :destroyOnClose="true" wrap-class-name="empty-modal"
    @ok="creditModal = false" @cancel="creditModal = false">
    <div class="p-4">
      <h2>Enter Sent code:</h2>
      <Row>
        <Col :span="24">
        <CustumInput name="code" type="text" :defaultData="phoneNumber" :defaultError="null" label="Code: "
          placeholder="" :rules="{
            required: true,
          }" @setInput="setInput2" class="w-full" />
        </Col>
      </Row>
      <Button type="primary" block class="mt-4" :loading="addCreditLoading" :disabled="loading"
        @click="applyCode">Submit</Button>
    </div>
  </Modal>
  <div class="flex flex-col gap-3">
    <div>
      <Checkbox :value="user?.enable_otp" :default-checked="user?.enable_otp" @change="setInput3"><span
          class="text-black">Enable two-factor authentication (Adds an extra layer of security to your account)</span>
      </Checkbox>
    </div>
    <div v-if="user?.enable_otp">
      <Checkbox :value="user?.otp_enabled" :default-checked="user?.otp_enabled" @change="setInput"><span
          class="text-black">I consent to receive SMS and Email notifications for multi-factor authentication (MFA)
          purposes. By checking this box, you agree to receive one-time passcodes and
          security-related notifications via SMS and Email. message or data rates will apply.</span></Checkbox>
    </div>
    <div v-if="user?.enable_otp">
      <h1 class="text-gray-700">Commutrics Security Alerts</h1>
      <h2 class="text-gray-700">Terms and Policies</h2>
      <ul>
        <li>
          <a href="https://employer.commutrics.com/terms-and-conditions" target="_blank">Terms and Conditions</a>
        </li>
        <li>
          <a href="https://tool.commutrics.com/privacy-policy" target="_blank">Privacy Policy</a>
        </li>
      </ul>

      <h2 class="text-gray-700">Message Frequency</h2>
      <p>Messages will be sent each time you log in to secure your account.</p>

      <h2 class="text-gray-700">Opt-Out Information</h2>
      <p>You may opt out at any time by <strong>Unchecking</strong> the checkbox.</p>

      <h2 class="text-gray-700">Customer Care</h2>
      <ul>
        <li>
          <strong>Email:</strong> <a href="mailto:info@commutrics.com">info@commutrics.com</a>
        </li>
        <li><strong>Phone:</strong> 720-666-9672</li>
      </ul>
    </div>

    <div v-if="user?.enable_otp">
      <List v-if="user?.otp_enabled" bordered :data-source="user.emails_alt ?? []" :locale="{ emptyText: 'No data' }">
        <template #renderItem="{ item }">
          <ListItem>
            <div class="flex justify-between w-full">
              <p>{{ item }}</p>
              <Popconfirm title="Are you sure you want to decline?" ok-text="Yes" cancel-text="No" @confirm="() => {
                  deleteEmail(item);
                }
                ">
                <Button type="danger" size="small" title="Decline"
                  class="btn-warning rounded shadow flex items-center gap-2">
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </ListItem>
        </template>
        <template #header>
          <div>One-time password emails</div>
        </template>
        <template #footer>
          <div class="w-full">
            <Row class="w-full">
              <CustumInput name="email" type="email" :defaultData="emailAddress" :defaultError="null"
                label="OTP Email Address" placeholder="john.doe@domain.com" :rules="{
                  email: true,
                  min: 3,
                }" class="w-full" @setInput="setInput2" />
              <Button type="primary" class="btn-gradient rounded mt-1 w-1/2" size="default" block @click="addEmil"
                :disabled="loading">Add</Button>
            </Row>
          </div>
        </template>
      </List>
      <List v-if="user?.otp_enabled" bordered :data-source="user.phone_numbers_alt ?? []"
        :locale="{ emptyText: 'No data' }">
        <template #renderItem="{ item }">
          <ListItem>
            <div class="flex justify-between w-full">
              <p>{{ item }}</p>
              <Popconfirm title="Are you sure you want to decline?" ok-text="Yes" cancel-text="No" @confirm="() => {
                  deletePhoneNumber(item);
                }
                ">
                <Button type="danger" size="small" title="Decline"
                  class="btn-warning rounded shadow flex items-center gap-2">
                  <PhX weight="duotone" :size="16" />
                </Button>
              </Popconfirm>
            </div>
          </ListItem>
        </template>
        <template #header>
          <div>One-time password phone numbers</div>
        </template>
        <template #footer>
          <div class="w-full">
            <Row class="w-full">
              <CustumInput name="phone" type="text" :defaultData="phoneNumber" :defaultError="null"
                label="OTP Phone number(Include country code)" placeholder="+111111111" :rules="{
                  email: false,
                }" @setInput="setInput2" class="w-full" />
              <Button type="primary" class="btn-gradient rounded mt-1 w-1/2" size="default" block @click="addPhone"
                :disabled="loading">Add</Button>
            </Row>
          </div>
        </template>
      </List>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumPhoneSelect from '@/components/shared/form/CustumPhoneSelect.vue';
import authApi from '@/services/apis/auth.api';
import { AUTH } from '@/store/types';
import handleResErrors from '@/utils/handleResErrors';
import {
  Button,
  Checkbox,
  Col,
  List,
  ListItem,
  message,
  Modal,
  Popconfirm,
  Row,
} from 'ant-design-vue';
import { PhX } from 'phosphor-vue';
export default {
  data() {
    return {
      formData: {},
      newOTPEmail: null,
      newOTPPhone: null,
      creditModal: false,
      sentOTP: null,
      loading: false,
    };
  },
  components: {
    List,
    ListItem,
    CustumInput,
    Button,
    CustumPhoneSelect,
    Checkbox,
    Row,
    Col,
    Popconfirm,
    PhX,
    Modal,
  },
  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
    emailAddress() {
      return this.newOTPEmail;
    },
    phoneNumber() {
      return this.newOTPPhone;
    },
  },
  methods: {
    setInput(vals) {
      this.formData = { otp_enabled: vals?.target?.checked };
      this.handleSubmit();
    },
    setInput3(vals) {
      this.formData = { enable_otp: vals?.target?.checked };
      this.handleSubmit();
    },
    setInput4(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    setInput2(vals) {
      if (vals?.email) {
        this.newOTPEmail = vals?.email?.value;
      }
      if (vals?.phone) {
        this.newOTPPhone = vals?.phone?.value;
      }
      if (vals?.code) {
        this.sentOTP = vals?.code?.value;
      }
    },
    async handleSubmit() {
      this.loading = true;

      try {
        const res = await authApi.updateUser(this.formData);
        if (res.status) {
          message.success('Updated successfully');
          this.$store.commit(AUTH.SET_USER_DATA, res);
        }

        this.isLoading = false;
      } catch (error) {
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          this.responseErrors = errorMsg;
        }

        message.error(handleResErrors(error), 10);
      }
      this.loading = false;
    },
    async addEmil() {
      this.loading = true;

      try {
        if (this.newOTPEmail) {
          const res = await authApi.addEmail(this.newOTPEmail);
          if (res) {
            const data = await authApi.getUser();
            this.$store.commit(AUTH.SET_USER_DATA, data);
            message.success('email added successfully');
            this.newOTPEmail = null;
            this.creditModal = true;
          }
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.loading = false;
    },
    async addPhone() {
      this.loading = true;
      try {
        if (this.newOTPPhone) {
          if (this.newOTPPhone.startsWith("+")) {

            const res = await authApi.addPhoneNumber(this.newOTPPhone);
            if (res) {
              const data = await authApi.getUser();
              this.$store.commit(AUTH.SET_USER_DATA, data);
              message.success('phone added successfully');
              this.newOTPPhone = null;
              this.creditModal = true;
            }
          }else {
            message.warning("Please type phone with country code +");
          }
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.loading = false;
    },
    async deletePhoneNumber(phone) {
      this.loading = true;

      try {
        const res = await authApi.removePhoneNumber(phone);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('phone added successfully');
          this.newOTPPhone = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.loading = false;
    },
    async deleteEmail(email) {
      this.loading = true;

      try {
        const res = await authApi.removeEmail(email);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('phone added successfully');
          this.newOTPPhone = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.loading = false;
    },
    async applyCode() {
      this.loading = true;

      try {
        const res = await authApi.acceptMethod(this.sentOTP);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('method added successfully');
          this.creditModal = false;
          this.newOTPPhone = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.loading = false;
    },
  },
};
</script>
