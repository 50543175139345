<template>
  <div class="scores-page py-4 mt-4">
    <div class="container">
      <section class="members-data">
        <div class="w-full main-card px-3 md:px-4 lg:px-5 py-4 lg:py-5 xl:py-7">
          <div class="w-full mt-4 flex flex-wrap items-center justify-between">
            <h2 class>Top performing members</h2>
            <div class="flex items-center gap-3">
              <!-- <range-picker
                :placeholder="['Start year', 'End year']"
                picker="year"
                v-model:value="years"
              />-->

              <div>
                <!-- <a-date-picker v-model:value="value1" /> -->
                <date-picker v-if="type !== 'all'" :picker="type" v-model:value="dateVal" />
              </div>

              <CustumSelect
                name="type"
                placeholder="select type "
                :data="dateData"
                :defaultData="type"
                :rules="{
                  required: false,
                }"
                @setInput="setInput"
              />
            </div>
          </div>

          <div
            class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4"
            v-if="scoresQuery &&   scoresQuery.isLoading "
          >
            <div
              class="flex items-center gap-3 border border-bg-dark shadow border-solid p-4 rounded-lg"
              v-for="ix in 10"
              :key="ix"
            >
              <skeleton-avatar active size="large" />
              <skeleton-input active size="large" class="flex-1" />
            </div>
          </div>

          <QueryAlert :query="scoresQuery" />

          <div v-if="scoresData && scoresData.data">
            <ScoresTable :data="scoresData.result" :meta="scoresData.meta" @setPageNum="setPageNum" @setPageSize="setPageSize" />
          </div>

          <!-- <div
            v-if="scoresData && scoresData.data"
            class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4"
          >
            <SingleMember v-for="member in scoresData.data" :key="member.id" :member="member" />
          </div>-->
          <!--
          <div
            v-if="scoresQuery && !scoresQuery.isLoading && scoresData && scoresData.data && !scoresData.data.length "
            class="py-12 lg:py-16"
          >
            <Empty />
          </div>-->

          <!-- <div v-if="scoresData && scoresData.data" class="mt-6 flex items-center justify-center">
            <Pagination
              :current="page"
              :total="total"
              show-less-items
              :page-size="pageSize"
              @change="handlePaginationChange"
            />
          </div>-->

          <div class="mt-2 md:mt-3 lg:mt-4"></div>
        </div>
      </section>
    </div>

    <ScoresQuery
      :page="page"
      :per_page="pageSize"
      :years="getYears"
      :type="type"
      :key="`${scoresKey}-${page}-${pageSize}`"
      :custumDate="custumDate"
    />
  </div>
</template>
<script>
/* eslint-disable */
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import QueryAlert from '@/components/shared/QueryAlert.vue';
import ScoresQuery from '@/services/queries/scores/ScoresQuery.vue';
import { SkeletonAvatar, SkeletonInput, DatePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
// import SingleMember from './SingleMember.vue';
import ScoresTable from './ScoresTable.vue';

export default {
  components: {
    // SingleMember,
    CustumSelect,

    ScoresQuery,
    SkeletonAvatar,
    SkeletonInput,
    // Empty,
    // Pagination,
    QueryAlert,
    // RangePicker,
    ScoresTable,
    DatePicker,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      type: 'year',
      years: [],

      dateVal: dayjs(new Date().getFullYear() + '/12', 'YYYY/MM'),

      dateData: [
        // { id: 1, name: 'custom date' },
        { id: 'month', name: 'Monthly' },
        { id: 'quarter', name: 'Quarterly' },
        { id: 'year', name: 'Yearly' },
        { id: 'all', name: 'All Data' },
      ],
    };
  },
  computed: {
    getYears() {
      // if (this.years && this.years[0] && this.years[1]) {
      //   return {
      //     from_year: dayjs(this.years[0]).year(),
      //     to_year: dayjs(this.years[1]).year(),
      //   };
      // }
      return null;
    },
    scoresKey() {
      let key = `${this.page}-${this.pageSize}-${this.type}`;
      if (this.getYears && this.getYears.from_year && this.getYears.to_year) {
        key += `-${this.getYears.from_year}-${this.getYears.to_year}`;
      }

      if (this.custumDate && this.custumDate.from && this.custumDate.to) {
        key += `-${this.custumDate.from}-${this.custumDate.to}`;
      }

      return key;
    },
    scoresQuery() {
      return this.$store.state.scores.scoresQuery;
    },
    scoresData() {
      return this.$store.getters.scoresData;
    },

    pagination() {
      if (!this.scoresData) {
        return {
          defaultPageSize: this.pageSize,
          total: 0,
        };
      }

      return {
        defaultPageSize: this.scoresData?.meta?.per_page || this.pageSize,
        total: this.scoresData?.meta?.total || 0,
      };
    },
    custumDate() {
      if (this.type === 'all' || !this.dateVal) {
        return null;
      }

      const dates = { from: null, to: null };

      if (this.type === 'month') {
        dates.from = dayjs(this.dateVal)
          .startOf('month')
          .format('YYYY-MM-DD');
        dates.to = dayjs(this.dateVal)
          .endOf('month')
          .format('YYYY-MM-DD');
      }

      if (this.type === 'quarter') {
        dates.from = dayjs(this.dateVal)
          .startOf('month')
          .format('YYYY-MM-DD');
        dates.to = dayjs(this.dateVal)
          .add(2, 'month')
          .endOf('month')
          .format('YYYY-MM-DD');
      }

      if (this.type === 'year') {
        dates.from = dayjs(this.dateVal)
          .startOf('year')
          .format('YYYY-MM-DD');
        dates.to = dayjs(this.dateVal)
          .endOf('year')
          .format('YYYY-MM-DD');
      }

      return dates;
    },
  },
  watch: {
    scoresData: {
      handler(val) {
        if (val?.meta?.total) {
          this.total = val?.meta?.total;
        }
        if (val?.meta?.per_page) {
          this.pageSize = val?.meta?.per_page;
        }
      },
      deep: true,
    },
  },
  methods: {
    setInput(val) {
      if (val.type && val.type.value) {
        this.type = val.type.value;
      }
    },
    handlePaginationChange(page, pageSize) {
      // , filters, sorter, data
      this.page = page;
      this.pageSize = pageSize;
    },
    setPageNum(page) {
      this.page = page;
    },
    setPageSize(page) {
      this.pageSize = page;
    },
  },
};
</script>
