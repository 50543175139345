<template>
  <div class="container py-12 md:py-16 lg:py-20 xl:py-24">
    <AuthCard title="Login as Employer or Community Leader">
      <form @keydown.enter.prevent="handleSubmit">
        <!-- <p
          class="text-center text-sm mb-6 text-text-light"
        >Login or Create Account for Your Community</p>-->
        <div class="flex flex-col gap-4">
          <CustumInput
            name="email"
            type="email"
            :defaultData="defaultData ? defaultData.email : null"
            label="Email Address"
            placeholder="john.doe@domain.com"
            :rules="{
              required: true,
              email: true,
              min: 3,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumInputPassword
            name="password"
            :defaultData="defaultData ? defaultData.password : null"
            label="Password"
            placeholder="password ..."
            :rules="{
              required: true,
              min: 6,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
          <div class="flex items-center justify-between">
            <Checkbox>Remember me</Checkbox>

            <router-link to="/forget-password">Forgot Password?</router-link>
          </div>
        </div>

        <div class="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            class="btn-gradient rounded"
            size="large"
            block
            :loading="isLoading"
            @click.prevent="handleSubmit"
            >Login</Button
          >

          <p class="text-center mt-4 opacity-80">
            Don't have an account?
            <router-link to="/register">Register here</router-link>
          </p>
        </div>
      </form>
    </AuthCard>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <AlertModal :warningMessage="warningMessage" @close="handleModalClose" />
    </Modal>
    <Modal
      :visible="visible2"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      width="700px"
    >
      <div v-if="!secondStep">
        <PageHeader
          title="Select the method for the second verification"
          sub-title="One time password"
        />
        <Divider />
        <FormItem label="Select method to authenticate">
          <RadioGroup :value="otpMethod" @change="setMethod">
            <RadioButton value="phone">Phone</RadioButton>
            <RadioButton value="email">Email</RadioButton>
          </RadioGroup>
        </FormItem>
        <h2>Select {{ otpMethod == 'email' ? 'an email' : 'a phone number' }}</h2>
        <Select
          v-model:value="value"
          show-search
          :options="methods"
          :filter-option="filterOption"
          @change="setMethodNumber"
          class="w-full"
        ></Select>
        <Button type="primary" class="w-full mt-2" @click="selectMethod" :disabled="isLoading">Send</Button>
      </div>
      <div v-if="secondStep">
        <PageHeader
          title="Enter the sent code please"
          sub-title="One time password"
          @back="
            () => {
              secondStep = secondStep ? false : false;
            }
          "
        />
        <CustumInput
          name="otp"
          type="text"
          :defaultData="null"
          label="OTP - one time password"
          :rules="{
            required: false,
            email: false,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />
        <Button
          type="primary"
          htmlType="submit"
          class="btn-gradient rounded w-full mt-2"
          size="large"
          block
          :loading="isLoading"
          @click.prevent="handleSubmit"
          >Login</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable */
import validation from '@/services/validation';
import { ref } from 'vue';
import {
  message,
  Checkbox,
  Button,
  Modal,
  PageHeader,
  Divider,
  RadioGroup,
  RadioButton,
  FormItem,
  Select,
} from 'ant-design-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumInputPassword from '@/components/shared/form/CustumInputPassword.vue';
import authApi from '@/services/apis/auth.api';
import handleResErrors from '@/utils/handleResErrors';
import AuthCard from './AuthCard.vue';
import AlertModal from './AlertModal.vue';
import { saveToken } from '../../services/jwt.service';

export default {
  components: {
    AuthCard,
    CustumInput,
    Divider,
    CustumInputPassword,
    FormItem,
    Checkbox,
    Button,
    Select,
    AlertModal,
    RadioGroup,
    RadioButton,
    PageHeader,
    Modal,
  },
  props: {
    defaultData: Object,
  },
  data() {
    return {
      isAllTouched: false,
      isLoading: false,
      formData: {},
      visible: false,
      visible2: false,
      warningMessage: '',
      otpMethod: ref('phone'),
      methodNumber: null,
      secondStep: false,
      email_methods: [],
      phone_methods: [],
    };
  },
  methods: {
    setMethodNumber(e) {
      this.methodNumber = e;
    },
    setMethod(e) {
      this.otpMethod = e.target.value;
    },
    handleModalClose() {
      this.visible = false;
      this.warningMessage = '';
    },
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    async handleSubmit(e) {
      e.target.blur();

      this.isAllTouched = true;

      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      /// submit
      this.isLoading = true;
      try {
        const res = await authApi.postLogin(validetionData.data);
        if (res.status) {
          if (res.is_active) {
            message.success('logged in successfully');
            saveToken(res.accessToken);
            this.$router.push('/dashboard');
          } else {
            this.visible = true;
            // eslint-disable-next-line operator-linebreak
            this.warningMessage =
              'We are sorry, your account is not active yet. Please contact Commutrics Customer Service.';
          }
        }
        this.isLoading = false;
      } catch (error) {
        if (error?.response?.data?.email_methods || error?.response?.data?.phone_methods) {
          this.visible2 = true;
          this.email_methods = error?.response?.data?.email_methods;
          this.phone_methods = error?.response?.data?.phone_methods;
        }else {
          message.error(handleResErrors(error));

        }

        this.isLoading = false;
      }
    },
    async selectMethod() {
      if ((this.methodNumber || this.methodNumber == 0) && this.otpMethod) {
        try {
          const validetionData = validation.validateAllData(this.formData);
          validetionData.data.otp = null;
          const res = await authApi.postMethodLogin(validetionData.data, {
            otp_method: this.otpMethod,
            method_number: this.methodNumber,
          });
          if (res.status) {
            if (res.is_active) {
              message.success('Message sent successfully');
              this.secondStep = true;
            } else {
              this.visible = true;
              // eslint-disable-next-line operator-linebreak
              this.warningMessage =
                'We are sorry, your account is not active yet. Please contact Commutrics Customer Service.';
            }
          }
          this.isLoading = false;
        } catch (error) {
          message.error(handleResErrors(error));
          this.isLoading = false;
        }
      }
      console.log(validation.validateAllData(this.formData));
    },
  },
  computed: {
    methods() {
      if (this.otpMethod == 'phone') {
        return this.phone_methods?.filter((v) => v)?.map((v, i) => {
            return { value: i, label: v };
          
        });
      } else if (this.otpMethod == 'email') {
        return this.email_methods?.map((v, i) => {
          return { value: i, label: v };
        });
      }
    },
  },
};
</script>
